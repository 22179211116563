// Background Hills ============================================================
function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const COW_COUNT = 18;
var cowCache = document.getElementsByClassName("bg-cow");
var restingCows = [];
var usedCowVars = [];

for(var i = 0; i < cowCache.length; ++i) {

	var currCow = cowCache.item(i);   
	restingCows.push(currCow);

	currCow.onanimationend = (e) => {
		e.srcElement.className = "bg-cow d-none d-md-block";
		usedCowVars.splice(usedCowVars.indexOf(e.srcElement.cownum), 1);
		e.srcElement.cownum = null;
		restingCows.push(e.srcElement);
	};

}

function onCowUpdate() {

	if(restingCows.length <= 0)
		return;

	var currCow = restingCows.pop();

	var cowVarNum = getRandomInt(0, COW_COUNT);
	while(usedCowVars.includes(cowVarNum)) {

		cowVarNum++;
		if(cowVarNum >= COW_COUNT)
			cowVarNum = 0;
	}

	usedCowVars.push(cowVarNum);
	currCow.cownum = cowVarNum;
	currCow.classList.add("bg-cowbob-anim", `landscape-${getRandomInt(1, 6)}`, `cow-var-${cowVarNum}`);
	currCow.style.left = `calc(${10 + Math.random() * 80}vw - 125px)`;
}

setInterval(onCowUpdate, 2000);